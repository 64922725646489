import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store'
import { AttachmentsResponse, EmailsWithSelection, Message } from '../api/types'

export type DateRangeSelectedItem = {
  value?: string
  dateRange?: {
    startDate: string
    endDate: string
  }
}
export type MessagePayload = {
  payloadType: 'drafts' | 'reply'
  messageId: string
  subject: string
  to: EmailsWithSelection[]
  body?: string
  attachments?: AttachmentsResponse[]
  hasAttachments: boolean
}
export type AlertType = {
  message: string
  type: string
  isVisible: boolean
}
type ISecureMessagesSliceState = {
  selectedTabValue: string
  viewMessageId?: string
  secureMessageSearchText?: string
  openAddNewMessage: boolean
  selectedMessageList?: Message[]
  secureMessageDateRange?: DateRangeSelectedItem
  secureMessagePayload?: MessagePayload
  sortedDatagridMessageIdList?: string[]
  alertState?: AlertType
  sortedDatagridList?: Message[]
}

const initialState: ISecureMessagesSliceState = {
  selectedTabValue: 'Inbox',
  secureMessageDateRange: { value: 'All' },
  openAddNewMessage: false,
  viewMessageId: '',
  secureMessageSearchText: ''
}

const Slice = createSlice({
  name: '@module/communicationCenter/secureMessages',
  initialState,
  reducers: {
    SetSelectedTabValue: (state, action: PayloadAction<string>) => {
      return { ...state, selectedTabValue: action.payload }
    },
    SetOpenAddNewMessage: (state, action: PayloadAction<boolean>) => {
      return { ...state, openAddNewMessage: action.payload }
    },
    SetSelecedMessageList: (state, action: PayloadAction<Message[]>) => {
      return { ...state, selectedMessageList: action.payload }
    },
    SetSecureMessageDateRange: (state, action: PayloadAction<DateRangeSelectedItem>) => {
      return { ...state, secureMessageDateRange: action.payload }
    },
    SetViewMessageId: (state, action: PayloadAction<string>) => {
      return { ...state, viewMessageId: action.payload }
    },
    SetSecureMessageSearchText: (state, action: PayloadAction<string>) => {
      return { ...state, secureMessageSearchText: action.payload }
    },
    SetSecureMessagePayload: (state, action: PayloadAction<MessagePayload>) => {
      return { ...state, secureMessagePayload: action.payload }
    },
    SetSortedDatagridMessageIdList: (state, action: PayloadAction<string[]>) => {
      return { ...state, sortedDatagridMessageIdList: action.payload }
    },
    SetSecureMessagesAlertState: (state, action: PayloadAction<AlertType>) => {
      return { ...state, alertState: action.payload }
    },
    SetSortedDatagridList: (state, action: PayloadAction<Message[]>) => {
      return { ...state, sortedDatagridList: action.payload }
    }
  }
})

export const {
  SetSortedDatagridList,
  SetSecureMessagesAlertState,
  SetViewMessageId,
  SetSelectedTabValue,
  SetSecureMessageDateRange,
  SetOpenAddNewMessage,
  SetSelecedMessageList,
  SetSecureMessageSearchText,
  SetSecureMessagePayload,
  SetSortedDatagridMessageIdList
} = Slice.actions
export const getSelectedTabValueSelector = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.selectedTabValue
export const getOpenAddNewMessageSelector = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.openAddNewMessage
export const getSelectedMessageList = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.selectedMessageList
export const getSecureMessageDateRange = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.secureMessageDateRange
export const getViewMessageId = (state: RootState) => state.modules.communicationCenter.secureMessages.viewMessageId
export const getSecureMessageSearchText = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.secureMessageSearchText
export const getSecureMessagePayload = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.secureMessagePayload
export const getSortedDatagridMessageIdList = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.sortedDatagridMessageIdList
export const getSecureMessagesAlertState = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.alertState
export const getSortedDatagridList = (state: RootState) =>
  state.modules.communicationCenter.secureMessages.sortedDatagridList
export default Slice.reducer
